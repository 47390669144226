import { useRef, useEffect, useState } from "react";
import logo from "./images/logo.webp";
import { FaTiktok } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { isNumeric, isLettersAndSpaces } from "./regexp/regexp";
import Timer from "./components/timer/Timer";
import Loader from "react-loaders";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [isInvalidName, setIsInvalidName] = useState(false);
  const [isInvalidTelephone, setIsInvalidTelephone] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const canvasRef = useRef(null);
  const nameInputRef = useRef();
  const emailInputRef = useRef();
  const telephoneInputRef = useRef();
  const formRef = useRef();
  let loader = <Loader type="ball-beat" />;
  let errorTest = <p>Przepraszamy, coś poszło nie tak... :(</p>;
  let successText = <p style={{ fontWeight: "700" }}>Dziękujemy!</p>;

  const resize = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    }
  };
  const generateNoise = (ctx) => {
    var w = ctx.canvas.width,
      h = ctx.canvas.height,
      idata = ctx.createImageData(w, h),
      buffer32 = new Uint32Array(idata.data.buffer),
      buffer_len = buffer32.length,
      i = 0;
    for (; i < buffer_len; i++) buffer32[i] = ((255 * Math.random()) | 0) << 24;
    ctx.putImageData(idata, 0, 0);
  };
  const animate = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        generateNoise(ctx);
      }
    }
    requestAnimationFrame(animate);
  };
  function handleSubmit(e) {
    e.preventDefault();
    const nameValue = nameInputRef.current?.value;
    const telephoneValue = telephoneInputRef.current?.value;
    const emailValue = emailInputRef.current?.value;

    const bodyValue = {
      name: nameValue,
      email: emailValue,
      telephone: telephoneValue,
    };

    if (!isLettersAndSpaces(nameValue)) {
      setIsInvalidName(true);
      nameInputRef.current.focus();
      return;
    }
    setIsInvalidName(false);

    if (!isNumeric(telephoneValue)) {
      setIsInvalidTelephone(true);
      telephoneInputRef.current.focus();
      return;
    }
    setIsInvalidTelephone(false);

    setIsSending(true);
    fetch(`${process.env.REACT_APP_API_URL}/mail/api/polo-send-email`, {
      // <- Do wpisania adress
      method: "POST",
      mode: "cors",
      body: JSON.stringify(bodyValue),
      headers: {
        "Content-Type": "application/json",
      },
    }).then(
      (result) => {
        if (result.status === 200) {
          setIsError(false);
          setIsSuccess(true);
        } else {
          console.log(result.status);
          console.log(result.statusText);
          throw new Error(result.status);
        }
      },
      (error) => {
        console.log(error);
        setIsError(true);
        setIsSuccess(false);
      }
    );
  }

  useEffect(() => {
    window.addEventListener("resize", resize);
    resize();
    animate();
    return () => window.removeEventListener("resize", resize);
  }, []);

  return (
    <div className="vh-100 d-flex flex-column align-items-center">
      <canvas id="canvas" ref={canvasRef}></canvas>
      <div className="App">
        <div className="container narkopolo-container d-flex justify-content-center align-items-center">
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            id="LogoHeader"
          >
            <img
              src={logo}
              className="company-logo"
              alt="Polonarko logo company"
            />
            <div className="form-header d-flex justify-content-center align-items-center">
              <Timer />
            </div>
          </div>
          <form
            className="form-container d-flex justify-content-center"
            onSubmit={handleSubmit}
            ref={formRef}
          >
            <div className="form-inputs d-flex justify-content-center align-items-center flex-wrap">
              <input
                className="form-control"
                type="text"
                placeholder="Imię"
                ref={nameInputRef}
                required
              />
              {isInvalidName && (
                <p className="incorrrectValue">
                  Prosze posługiwać się tylko literami bez żadnych znaków
                  interpunkcyjnych!
                </p>
              )}
              <input
                className="form-control"
                type="email"
                placeholder="Email"
                ref={emailInputRef}
                required
              />
              <input
                className="form-control"
                type="text"
                placeholder="Numer telefonu"
                ref={telephoneInputRef}
                required
              />
              {isInvalidTelephone && (
                <p className="incorrrectValue">
                  Proszę użyć tylko liczb od 0 do 9!
                </p>
              )}
              <p className="form-agreed">
                Wysyłając formularz wyrażasz zgodę na przetwarzanie danych
                osobowych oraz udzielasz zgodę na użycie twoich danych w celach
                marketingowych!
              </p>
              <div className="form-button">
                {!isSending && (
                  <button className="button-54" role="button" type="submit">
                    Wyślij!
                  </button>
                )}
                <div className="col-10 comunication-container">
                  {isSending && !isError && !isSuccess && loader}
                  {isError && isSending && errorTest}
                  {isSuccess && isSending && successText}
                </div>
              </div>
            </div>
          </form>
          <div
            className="d-flex flex-column justify-content-center"
            id="SocialRights"
          >
            <div className="social-media-container d-flex justify-content-center align-items-start">
              <a
                className="tiktok-media-link"
                href="https://www.tiktok.com/@polonarkoclothing?_t=8oDE4TgMO1o&_r=1"
                alt="PoloNarco Tiktok profile"
              >
                <FaTiktok className="FaTiktok" />
              </a>
              <a
                className="instagram-media-link"
                href="https://www.instagram.com/polonarkoclothing?igsh=MWI2ZmhzcmxoN2I1cg%3D%3D&utm_source=qr"
                alt="PoloNarco Instagram profile"
              >
                <FaInstagram className="FaInstagram" />
              </a>
            </div>
            <div className="copyrights-container d-flex justify-content-between ">
              <p>POLONARKO ©</p>
              <p>
                CREATED BY{" "}
                <a
                  className="fish-link"
                  href="https://www.facebook.com/profile.php?id=100084920133269"
                >
                  FISHWEB
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
